.container {
  padding: 12px 15px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-image: linear-gradient(to right, #1d75bd, #434344);
  position: fixed;
  top: 80px;
  right: 25px;
  min-width: 300px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.3);
  z-index: 99;
}

.container * {
  margin: 0;
}

.callerName {
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 3px;
}

.roomName {
  font-size: 13px;
  color: #f3f3f3;
}

.callButtons {
  display: flex;
  align-items: center;
  column-gap: 15px;
}

.callButtons button {
  border: none;
  outline: none;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.3s ease;
}
.callButtons button:active {
  transform: scale(0.95);
}

.acceptButton {
  background-color: rgb(0, 199, 0);
  fill: #222;
  animation: acceptCall 1.5s infinite;
}

.rejectButton {
  background-color: rgb(230, 15, 0);
  fill: #fff;
}

@keyframes acceptCall {
  40%,
  50%,
  70%,
  90% {
    transform: rotate(10deg);
  }

  0%,
  20%,
  40%,
  60%,
  80% {
    transform: rotate(0deg);
  }
}
