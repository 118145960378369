.mediaDisplayContainer {
  position: fixed;
  width: 70%;
  height: 85%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%) scale(0);
  z-index: 1600;
  background: #242424;
  padding: 30px 20px;
  padding-top: 40px;
  border-radius: 5px;
  animation: scaleUp 0.3s ease-in-out forwards;
}

.closeBtn {
  position: absolute;
  top: 10px;
  right: 15px;
  padding: 4px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.closeBtn svg {
  fill: #fff;
  transition: all 0.3s;
  width: 20px;
  height: 20px;
}

.closeBtn:hover {
  background: #44444480;
}
.closeBtn:hover svg {
  fill: #da0e0e;
}

.mediaDisplayOverlay {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: #000000b9;
  position: fixed;
  z-index: 1500;
}

.mediaDisplayContent {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mediaDisplayContent img,
.mediaDisplayContent video {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

@keyframes scaleUp {
  from {
    transform: translate(-50%, -50%) scale(0);
  }
  to {
    transform: translate(-50%, -50%) scale(1);
  }
}

@media only screen and (max-width: 768px) {
  .mediaDisplayContainer {
    width: 85%;
  }
}

@media only screen and (max-width: 640px) {
  .mediaDisplayContainer {
    height: auto;
    padding: 0;
  }
  .closeBtn {
    display: none;
  }
}
