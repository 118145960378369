.heading {
  margin-bottom: 50px;
  text-align: center;
}

@media (max-width: 768px) {
  .heading {
    margin-bottom: 30px;
  }
}
