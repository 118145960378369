.container {
  padding-left: 73px;
  padding-top: 100px;
}

.innerContainer {
  max-width: 80%;
  margin: auto;
}

@media (max-width: 1280px) {
  .innerContainer {
    max-width: 85%;
  }
}

@media (max-width: 768px) {
  .innerContainer {
    max-width: 90%;
  }
}

@media (max-width: 600px) {
  .container {
    padding-left: 57px;
  }
}
