.mediaList {
  display: grid;
  width: 100%;
  grid-template-columns: repeat(4, 1fr);
  gap: 20px;
  row-gap: 30px;
  margin-bottom: 60px;
  overflow: hidden;
  grid-auto-flow: row dense;
}

.card {
  width: 100%;
  position: relative;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  justify-items: center;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  border: 1px solid #aaaaaa;
  min-height: 150px;
  max-height: 250px;
}

.card:has(audio) {
  min-height: 200px;
}

.cardSkeleton {
  height: 250px;
}

.imageContainer {
  height: 100%;
  position: relative;
  text-align: center;
}

.imageContainer:has(audio) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
}
.imageContainer audio {
  width: 100%;
}

.imageContainer:has(.documentContainer) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.documentContainer p {
  margin: 0;
  text-align: center;
}
.documentContainer svg {
  width: 24px;
  height: 24px;
}

.documentContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.card img,
.card video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.downloadBtn {
  position: absolute;
  bottom: 12px;
  right: 12px;
  border-radius: 2px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  z-index: 4;
  border: none;
  outline: none;
}

.downloadBtn svg {
  width: 26px;
  height: 26px;
}

.loadMoreBtn {
  margin: auto;
  display: block;
  margin-bottom: 80px;
  border: none;
  background: #2c84cd;
  border-radius: 4px;
  padding: 2px 10px;
  color: #fff;
  font-size: 17px;
}

.videoPlayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
}
.videoPlayBtn svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
  /* fill: #ebebeb; */
}

.paginationContainer {
  display: flex;
  justify-content: center;
}

.paginationContainer a {
  text-decoration: none;
}

@media (max-width: 1280px) {
  .mediaList {
    grid-template-columns: repeat(3, 1fr);
  }
  .card {
    min-height: 130px;
  }

  .card:has(audio) {
    min-height: 180px;
  }
  .imageContainer:has(audio) {
    justify-content: flex-start;
    padding-top: 20px;
  }
}

@media (max-width: 1024px) {
  .mediaList {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 640px) {
  .mediaList {
    grid-template-columns: repeat(1, 1fr);
  }
}
