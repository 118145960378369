body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.PhoneInputInput {
  flex: 1;
  border-color: none;
  border: none;
  outline: none;
  box-shadow: 0px 2px 1px rgb(0 0 0 /25%);
  border-radius: 11px;
  padding: 0.5rem;
  background: #ffffff;
}

.responsive {
  padding-left: 180px;
  text-transform: capitalize;
}

.pl {
  margin-top: 100px;
  padding-bottom: 20px;
  padding: 0px 2px 0px 62px !important;
}

.chat_box p {
  margin: 0;
}

.chat-loading-spinner-container {
  height: 80vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-loading-spinner-container svg {
  width: 50px;
  height: 50px;
}

#chat_container .chat_main {
  /* max-width: 1000px; */
  position: relative;
  top: 0;
  bottom: 0;
  height: calc(100vh - 74px);
  margin: auto;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.06), 0 2px 5px 0 rgba(0, 0, 0, 0.06);
}

#chat_container .chat_box .chat-scroll-to-bottom-btn {
  position: fixed;
  bottom: 80px;
  right: 30px;
  background: #2682ce;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  z-index: 20;
  animation: float 2s ease-in-out infinite;
}

#chat_container .chat_box .chat-scroll-to-bottom-btn:active svg {
  transform: scale(0.9);
}

#chat_container .chat_box .chat-scroll-to-bottom-btn svg {
  fill: #fff;
  width: 22px;
  height: 22px;
  transition: all 0.3s ease-out;
}

@keyframes float {
  0% {
    transform: translateY(2px);
  }
  50% {
    transform: translateY(0px);
  }
  100% {
    transform: translateY(2px);
  }
}

#chat_container .chat-load-more-btn {
  /* width: 170px; */
  min-height: 26px;
  margin: auto;
  display: block;
  border: none;
  background: #2c84cd;
  border-radius: 20px;
  padding: 2px 10px;
  color: #fff;
  margin-bottom: 20px;
}
#chat_container .chat-load-more-btn svg {
  width: 20px;
  height: 20px;
  margin: auto;
}

#chat_container .cover::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url("../public/assets/images/chatCover.png");
  opacity: 0.09;
}

#chat_container .chat_header {
  position: relative;
  width: 100%;
  height: 40px;
  background: #2175bc;
  color: #fff;
  font-weight: 700;
  padding: 10px 20px;
}

#chat_container .chat_header .backBtn {
  background: transparent;
  border: none;
}
#chat_container .chat_header .backBtn svg {
  fill: #fff;
  width: 24px;
  height: 24px;
}

#chat_container .chat_box {
  position: relative;
  width: 100%;
  height: calc(100% - 100px);
  padding: 20px 14%;

  overflow-y: auto;
}

#chat_container .chat_box .message {
  position: relative;
  display: flex;
  width: 100%;
  white-space: pre-wrap;
  word-break: break-all;
  margin-bottom: 6px;
  /* margin-bottom: 1px; */
}

#chat_container .chat_box #message-media-content .media-loading {
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
}
#chat_container .chat_box #message-media-content .media-loading svg {
  width: 35px;
  height: 35px;
}

#chat_container .chat_box #message-media-content .media-loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #00000046;
  z-index: 90;
}

#chat_container .chat_box .message #message-media-content .message-time {
  position: absolute;
  right: 12px;
  bottom: 5px;
}
#chat_container .chat_box .message #message-media-content img,
#chat_container .chat_box .message #message-media-content video {
  max-width: 400px;
  object-fit: contain;
  max-height: 500px;
}

#chat_container .chat_box .message.message-video-container {
  position: relative;
}

#chat_container .chat_box .message-video-container-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50.5%, -51%);
  width: calc(100% - 20px);
  height: calc(100% - 10px);
  background: #0000002d;
}

#chat_container .chat_box .message .message-video-action-btns {
  display: flex;
  gap: 10px;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}

#chat_container .chat_box .message .message-video-play-btn,
.chat_box .message:has(video) .message-content-download-btn {
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
}
#chat_container .chat_box .message .message-video-play-btn svg {
  width: 50px;
  height: 50px;
  cursor: pointer;
  /* fill: #ebebeb; */
}

.chat_box .message:has(video) .message-content-download-btn {
  padding: 0;
  height: 38px;
  width: 50px;
  border-radius: 5px;
  background: #000000;
  display: flex;
  justify-content: center;
  align-items: center;
}
.chat_box .message:has(video) .message-content-download-btn svg {
  fill: #fff;
  width: 24px;
  height: 24px;
}

#chat_container .chat_box .message-img-download-btn {
  position: absolute;
  width: 30px;
  height: 30px;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 1);
}

#chat_container .chat_box .message-audio-container {
  /* width: 100px; */
  padding-bottom: 10px;
}

#chat_container .chat_box .message-audio-container audio {
  height: 30px;
  max-width: 100%;
  transform: translateX(-10px);
}

#chat_container
  .chat_box
  .message-audio-container
  audio::-webkit-media-controls-play-button,
#chat_container
  .chat_box
  .message-audio-container
  audio::-webkit-media-controls-panel {
  background-color: #d9fdd3;
}

#chat_container
  .chat_box
  .friend_message
  .message-audio-container
  audio::-webkit-media-controls-panel,
#chat_container
  .chat_box
  .friend_message
  .message-audio-container
  audio::-webkit-media-controls-play-button {
  background-color: #fff;
}

#chat_container .chat_box .message-audio-container .message-audio-name {
  margin-left: 10px;
  margin-top: 2px;
}

/* hide corrupted images */
#chat_container .chat_box .message:has(img.hide-media) {
  display: none;
}

#chat_container .chat_box .message .message-content {
  position: relative;
  right: 0;
  text-align: left;
  max-width: 65%;
  background: #d9fdd3;
  display: flex;
  gap: 10px;
  padding: 7px 10px;
  border-radius: 7.5px;
  font-size: 14.2px;
  margin-bottom: 0px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
}
#chat_container .sendBtn {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  background: #2175bc;
}

#chat_container .chat_box .message .message-content::before {
  content: "";
  position: absolute;
  top: 0;
  right: -10px;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    135deg,
    #d9fdd3,
    0%,
    #d9fdd3,
    50%,
    transparent,
    50%,
    transparent
  );
}
.chat_box .message .message-content span {
  display: block;
  margin-top: 7px;
  opacity: 0.85;
  font-size: 0.6875rem;
  color: #667781;
  white-space: nowrap;
  align-self: flex-end;
  /* padding-bottom: 4px; */
}

.chat_box .message .message-content-document-container {
  display: flex;
  align-items: flex-end;
  gap: 5px;
  /* padding: 3px auto; */
  padding: 4px;
  margin-bottom: 18px;
}
.chat_box .message .message-content-document-container p svg {
  width: 20px;
  height: 20px;
  transform: rotate(30deg) translate(2px, -2px);
}
.chat_box .message .message-content-document-container p {
  transform: translateX(-5px);
}

.chat_box .message .message-content-document-download-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding-bottom: 2px;
}

.chat_box .date-header {
  font-size: 12px;
  color: #667781;
  text-align: center;
  margin: 10px 0;
  font-weight: 500;
}

#chat_container .chat_box .my_message {
  justify-content: flex-end;
}

#chat_container .chat_box .friend_message {
  justify-content: flex-start;
}

#chat_container .chat_box .friend_message .message-content {
  background: #fff;
  margin-bottom: 2px;
  padding-left: 11px;
}

#chat_container .chat_box .friend_message .message-content::before {
  content: "";
  position: absolute;
  top: 0;
  left: -10px;
  width: 20px;
  height: 20px;
  background: linear-gradient(
    225deg,
    #fff,
    0%,
    #fff,
    50%,
    transparent,
    50%,
    transparent
  );
}

#chat_container .chat_input {
  position: relative;
  width: 100%;
  height: 60px;
  background: #f0f0f0;
  padding: 15px 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

#chat_container .chat_input .MuiSvgIcon-root {
  cursor: pointer;
  font-size: 1.8em;
  color: #51585c;
}
#chat_container .chat_input .type_input {
  position: relative;
  width: 90%;
  margin: 0 20px;
  padding: 10px 20px;
  border: none;
  outline: none;
  border-radius: 5px;
  resize: none;
}

@media (max-width: 1280px) {
  #chat_container .chat_box .message #message-media-content img,
  #chat_container .chat_box .message #message-media-content video {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  #chat_container .chat_box {
    padding: 20px 7%;
  }
}

@media only screen and (max-width: 900px) {
  .pl {
    padding: 0px 2px 0px 62px !important;
    margin-top: 100px;
  }
  .responsive {
    font-size: 20px;
    padding: 0;
  }
  .GoToBackIcon {
    height: 30px !important;
    width: 30px !important;
    padding: 0;
  }
  .responsive h4 {
    font-size: 16px;
  }
}

@media only screen and (max-width: 768px) {
  #chat_container .chat_box .message .message-content {
    max-width: 70%;
  }
}

@media only screen and (max-width: 600px) {
  .smallHidden {
    display: none;
  }
  #chat_container .chat_box {
    padding: 20px 4%;
  }

  #chat_container .chat_box .message .message-content:has(audio) {
    width: 100%;
    max-width: 80%;
  }

  #chat_container .chat_box .message-audio-container {
    width: 100%;
  }

  #chat_container .chat_box .message-audio-container audio {
    width: 100%;
  }

  #chat_container .chat_input {
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 480px) {
  #chat_container .chat_box .message .message-content:has(audio) {
    max-width: 90%;
  }
  #chat_container .chat_input {
    padding: 10px;
  }
}

#custom-phone-input .PhoneInput {
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
#custom-phone-input .PhoneInputInput {
  box-shadow: none;
}
