.mainContainer {
  min-height: 70vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.container {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 30px;
}

.container a {
  text-decoration: none;
  color: inherit;
}

.card {
  min-height: 200px;
  width: 100%;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
}

.cardSkeleton {
  background: #ccc;
}

.card p {
  font-size: 18px;
  font-weight: 500;
}

.card:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
}

.card:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}
