.mediaNav {
  display: flex;
  gap: 25px;
  justify-content: center;
  margin-bottom: 50px;
}

.mediaNav button {
  border: none;
  background-color: transparent;
  padding: 10px 3px;
  padding-bottom: 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  font-weight: 500;
  position: relative;
}

.mediaNav button:active {
  border: none;
  outline: none;
}

.mediaNav button::before {
  content: "";
  display: block;
  position: absolute;
  top: 100%;
  left: 0;
  width: 0;
  height: 3px;
  background-color: #000;
  transition: all 0.3s ease-out;
}

.mediaNav .activeBtn::before {
  width: 100%;
}

@media (max-width: 768px) {
  .mediaNav {
    gap: 15px;
  }
  .mediaNav button {
    font-size: 16px;
  }
}

@media (max-width: 500px) {
  .mediaNav {
    flex-wrap: wrap;
  }
}
