.container {
  margin-top: 50px;
}

.heading {
  font-weight: 600;
}

.headingContainer {
  display: flex;
  align-items: flex-end;
  margin-bottom: 80px;
  position: relative;
}

.dateTimeContainer {
  position: absolute;
  left: 50%;
  top: 0;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
}

.time {
  font-weight: 600;
  font-size: 32px;
  color: #1d75bd;
  margin-bottom: 0;
}

.time span {
  font-size: 20px;
}

.cardContainer {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 20px;
  row-gap: 30px;
}

.cardContainer a {
  text-decoration: none;
  color: inherit;
}

.card {
  min-height: 200px;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  text-align: center;
  padding: 10px;
}

.card p {
  font-size: 18px;
  font-weight: 500;
}

.card:hover {
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.25);
}

.card:active {
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1024px) {
  .headingContainer {
    margin-bottom: 40px;
  }
  .cardContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 30px;
  }
  .cardContainer a {
    max-width: 180px;
    width: 100%;
  }

  .headingContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .dateTimeContainer {
    position: relative;
    transform: none;
    left: 0;
  }
  .time {
    font-size: 24px;
  }
}

@media (max-width: 640px) {
  .headingContainer {
    flex-direction: column;
  }

  .cardContainer a {
    max-width: 45%;
  }
}
